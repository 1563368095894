<template>
  <div id="App">
    <van-nav-bar
      title="订单明细"
      left-text="返回"
      left-arrow="true"
      @click-left="onClickLeft"
    />
    <van-row type="flex">
      <van-col span="4"></van-col>
      <van-col span="18">
        <van-field
          :label="'会员卡号：' + hykh"
          label-width="100%"
          label-align="left"
        />
        <van-field
          :label="'预约日期：' + yyrq"
          label-width="100%"
          label-align="left"
        />
        <van-field
          :label="'预约时间：' + yysj"
          label-width="100%"
          label-align="left"
        />
        <van-field
          :label="'场地名称：' + cdmc"
          label-width="100%"
          label-align="left"
        />
        <van-field
          :label="'预约费用：￥' + fyhj"
          label-width="100%"
          label-align="left"
        />
        <van-field
          :label="'卡上余额：￥' + ksye"
          label-width="100%"
          label-align="left"
        />
        <van-field
          :label="'实际使用：' + sysj"
          label-width="100%"
          label-align="left"
        />
      </van-col>
      <van-col span="2"></van-col>
    </van-row>
    <van-divider style="color:red;font-size:x-large">{{ yyzt }}</van-divider>
    <center>
      <div id="qrcode"></div>
    </center>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Dialog } from "vant";
import { qrcanvas } from "qrcanvas";
export default {
  data() {
    return {
      hykh: "",
      yyrq: "",
      yysj: "",
      sysj: "",
      cdmc: "",
      fyhj: "",
      ksye: "",
      yyzt: "",
      timer: null
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  methods: {
    onClickLeft() {
      clearInterval(this.timer);
      this.$router.push("/selyylist");
    },
    qrcode(code) {
      var canvas = new qrcanvas({
        width: 132,
        height: 132,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        data: code // 二维码地址
      });
      document.getElementById("qrcode").innerHTML = "";
      document.getElementById("qrcode").appendChild(canvas);
    },
    dotimer() {
      return setInterval(() => {
        this.getData();
      }, 5000);
    },
    getData() {
      var that = this;
      let code = that.$route.query.code;
      that
        .$axios({
          method: "post",
          url: "/api/Main",
          data: {
            Method: "API_getYYXX",
            Params: [code]
          },
          headers: {
            Authorization: "Bearer " + that.userInfo.token
          }
        })
        .then(response => {
          if (response.data.status == "200") {
            that.hykh = response.data.Table[0].HYKH;
            that.yyrq = response.data.Table[0].YYRQ;
            that.yysj = response.data.Table[0].YYSJ;
            that.cdmc = response.data.Table[0].CDMC;
            that.fyhj = response.data.Table[0].FYHJ;
            that.ksye = response.data.Table[0].KSYE;
            that.sysj = response.data.Table[0].SYSJ;
            that.yyzt = response.data.Table[0].YYZT;
            that.qrcode(code);
          } else {
            Dialog({ message: response.data.msg });
            that.finished = true;
          }
        })
        .catch(error => {
          //弹出失败提醒
          Dialog({ message: error.message });
          that.finished = true;
        });
    }
  },
  created: function() {
    this.timer = this.dotimer();
    this.getData();
  },
  destroyed() {
    clearInterval(this.timer);
  }
};
</script>